	import React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './styles.css';

function article_1() {

	const codeSnippet = `
	exports.handler = async (event) => {
		return {
		  statusCode: 200,
		  body: JSON.stringify('Hello from Lambda!'),
		};
	  }; `;

	  

	return {
		date: "03 Feb 2024",
		title: "Exploring the Power of Serverless Architecture: A Comprehensive Overview	",
		description:
			"Building a Serverless Application with AWS Lambda, DynamoDB, and API Gateway.",
		keywords: [
			"The Benefits of Cloud Computing",
			"Sudip Adhikari",
			"Sudip Adhikari",
			"Sudip Adhikari",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					// align-self: center;
					// outline: 2px solid red;
					width: 100%;
					height: auto;
					border-radius: 8px 8px 0 0;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					
					<img
						src="https://www.xenonstack.com/hubfs/xenonstack-aws-serverless-computing.png"
						alt="random"
						className="randImage"
					/>
					<div className="paragraph">
						<div>
							<h3>Introduction</h3>
							<p>
								In recent years, serverless architecture has gained significant traction in the realm of cloud computing, revolutionizing 
								the way developers design, deploy, and manage applications. Leveraging services like Amazon S3, Lambda, API Gateway,
								DynamoDB, Cognito, IAM, RDS, and Aurora DB, developers can build highly scalable, resilient, and cost-effective 
								solutions without worrying about infrastructure management. In this article, we'll delve into the core components 
								of serverless architecture and explore how each service contributes to building robust applications.
							</p>
						</div>

						<div>
							<h3>Understanding Serverless Architecture:</h3>
							<p>
								At its core, serverless architecture abstracts away the underlying infrastructure, allowing developers to 
								focus solely on writing code. Amazon S3 serves as a scalable object storage service, ideal for storing and 
								retrieving vast amounts of data such as images, videos, and documents. Lambda, a key component of serverless 
								computing, enables developers to run code without provisioning or managing servers. By triggering functions 
								in response to events, Lambda facilitates event-driven and on-demand execution, offering unmatched flexibility 
								and efficiency.
							</p>
						</div>

						<div>
							<h3>Integration with API Gateway and DynamoDB:</h3>
							<p>
								API Gateway acts as a gateway for managing and securing APIs, providing features like authentication,
								authorization, and throttling. With API Gateway, developers can expose Lambda functions as HTTP endpoints,
								enabling seamless integration with web and mobile applications. DynamoDB, a fully managed NoSQL database 
								service, offers low-latency performance and automatic scaling, making it well-suited for applications requiring 
								fast and predictable data access patterns.
								
							</p>			
						</div>

						<div>
							<h3>Enhancing Security with Cognito and IAM:</h3>
							<p>
								Cognito simplifies user authentication and authorization for web and mobile applications, 
								offering features like user management, multi-factor authentication, and identity federation.
								IAM (Identity and Access Management) enables fine-grained control over access to AWS resources,
								 allowing developers to define policies and roles to enforce security best practices.	
							</p>			
						</div>

						<div>
							<h3>Data Management with RDS and Aurora DB:</h3>
							<p>
								RDS (Relational Database Service) provides managed relational databases, supporting popular engines like MySQL, 
								PostgreSQL, and SQL Server. With RDS, developers can offload database administration tasks such as patching, backups, 
								and scaling, focusing on application development instead. Aurora DB, a MySQL and PostgreSQL-compatible database built 
								for the cloud, delivers high performance, availability, and durability, making it an ideal choice for mission-critical 
								workloads.

							</p>			
						</div>

						<div style={{marginTop:'15px'}}>
							
							<h3>Building a Serverless Application</h3>
							<div>
								<br/>
								<h4>Step 1: Define the Application Architecture</h4>
								<p>Before diving into code, it's essential to outline the architecture of our serverless 
								application. Identify the Lambda functions, design the DynamoDB schema, and plan 
								the API Gateway endpoints.	
								</p>
							</div>

							<div>
								<br/>
								<h4>Step 2: Create Lambda Functions</h4>
								<p>Write the Lambda functions that will handle different aspects of your application. For 
									example, you might have functions for user registration, data retrieval, or processing
									user requests.
								</p>
								<SyntaxHighlighter language="javascript" style={atomDark}>
         							 {codeSnippet}
       							 </SyntaxHighlighter>
							</div>

							<div>
								<br/>
								<h4>Step 3: Set Up DynamoDB</h4>
								<p>Create a DynamoDB table and define the necessary indexes based on your 
									application's requirements. Consider the access patterns and design your schema 
									accordingly.
								</p>
							</div>
							<div>
								<br/>
								<h4>Step 4: Configure API Gateway</h4>
								<p>Use API Gateway to define your RESTful APIs and connect them to your Lambda
									functions. Set up authentication and authorization if needed, and deploy your API.
								</p>
							</div>
							<div>
								<br/>
								<h4>Step 5: Testing and Deployment</h4>
								<p>Test your serverless application locally to ensure everything works as expected. Once
									satisfied, deploy it to the AWS cloud, making it accessible to users.
								</p>
							</div>
							
							<div>
								<br/>
								<h4>Conclusion</h4>
								<p>
									Building a serverless application with AWS Lambda, DynamoDB, and API Gateway
									 provides a scalable and cost-effective solution. It allows developers to focus on writing 
									 code, accelerates time-to-market, and ensures high availability.
								</p>
								<p>
									As cloud computing continues to evolve, embracing serverless architecture becomes 
									increasingly essential. It's a paradigm shift that enables developers to build powerful
									and scalable applications without the complexity of managing infrastructure.
								</p>
								<p>
									In this article, we've explored the fundamental components of a serverless architecture
									and walked through the steps to create a simple serverless application using AWS
									Lambda, DynamoDB, and API Gateway. The possibilities with serverless computing are 
									vast, and this example serves as a starting point for your journey into the world of 
									serverless development. 
								</p>
							</div>
						</div>

						
						</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {

	const codeSnippet1 = `
	  const currentTime = new Date();
	  const currentHour = currentTime.getHours(); `;

	  const codeSnippet2 = `
	  let greetingMessage;

	  if (currentHour < 12) {
		greetingMessage = 'Good morning!';
	  } else if (currentHour < 18) {
		greetingMessage = 'Good afternoon!';
	  } else {
		greetingMessage = 'Good evening!';
	  }
	  `;

	  const codeSnippet3 = `
	  return (
		<div>
		  <h1>{greetingMessage}</h1>
		  {/* Other content of my portfolio */}
		</div>
	  );
	  `;

	  const codeSnippet4 = `
	  // Sample Lambda function to save contact form data in DynamoDB
	  exports.handler = async (event) => {
		  //code logic to save data in DynamoDB
		  return {
			statusCode: 200,
			body: JSON.stringify('Form data saved successfully!'),
		  };
		};
	  `;


	return {
		date: "4 Feb 2024",
		title: "A Dynamic Greeting on My Portfolio",
		description:
			"Bringing Personalization to the Digital Realm: A Dynamic Greeting on My Portfolio",
		style: ``,
		keywords: [
			"Artificial Intelligence in Healthcare",
			"Sudip Adhikari",
			"Sudip Adhikari",
			"Sudip Adhikari",
		],
		body: (
			<React.Fragment>
				
				<div className="article-content">
					
					<div className="paragraph">
						<div>
							<br/>
							<h3>Introduction</h3>
								<p>
									In the ever-evolving landscape of web development, the quest for creating unique and 
									engaging user experiences remains paramount. Inspired by the idea of offering a
									personalized touch to my portfolio, I embarked on a journey to implement a dynamic
									greeting based on the time of day. In this article, I'll walk you through the thought
									process, implementation steps, and the integration of a Lambda function for a 
									seamless user interaction.
								</p>
						</div>

						<div>
							<h4>Inspiration: A Warm Welcome in Real-Time</h4>
							<p>
								The concept stemmed from the desire to go beyond the static and conventional 
								elements of a portfolio. Imagine a website that welcomes visitors with a friendly
								greeting tailored to the time of day — a digital handshake that adapts to the user's
								experience. It's not just about displaying information; it's about creating an
								atmosphere.
							 </p>
						</div>

						<div>
							<h3>Implementation Steps</h3>
							<br/>
								<div>
									<h3>1. Identify the Time of Day</h3>
										<p>
											Using JavaScript, I tapped into the user's device to determine the current time. This 
											crucial piece of information would serve as the foundation for crafting personalized
											greetings.
										</p>
										<SyntaxHighlighter language="javascript" style={atomDark}>
         							 		{codeSnippet1}
       							 		</SyntaxHighlighter>
								</div>

								<div>
									<br/>
									<h3>2. Create Greeting Messages</h3>
										<p>
											With the time in hand, I designed different greeting messages for morning, afternoon, 
											evening, and night. Each message would bring a unique flavor to the user's experience.
										</p>
										<SyntaxHighlighter language="javascript" style={atomDark}>
         							 		{codeSnippet2}
       							 		</SyntaxHighlighter>
								</div>

								<div>
									<br/>
									<h3>3. Display Dynamic Greeting</h3>
										<p>
											Incorporating React, I seamlessly integrated the dynamic greeting into the portfolio.  
											The UI would now dynamically showcase a warm welcome based on the time of the 
											user's visit.
										</p>
										<SyntaxHighlighter language="javascript" style={atomDark}>
         							 		{codeSnippet3}
       							 		</SyntaxHighlighter>
								</div>

								<div>
									<br/>
									<h3>4. Integrate Lambda Functionality</h3>
										<p>
											Building upon the existing Lambda function responsible for handling the contact form  
											submissions, I ensured that the introduction of the dynamic greeting did not
											compromise any existing functionalities.
										</p>
										<SyntaxHighlighter language="javascript" style={atomDark}>
         							 		{codeSnippet4}
       							 		</SyntaxHighlighter>
								</div>
						</div>

						<div>
						<br/>
								<h4>Challenges and Solutions</h4>
								<p>
									No project is without its challenges. I encountered considerations related to time zones 
									and ensuring that the dynamic greeting was not affected by discrepancies. Through
									meticulous testing and adjustments, I addressed these challenges, creating a 
									seamless and reliable experience for users across different regions.
								</p>
						</div>

						<div>
						<br/>
								<h4>Testing and Deployment</h4>
								<p>
									After implementing the changes, thorough testing became imperative. I needed to 
									ensure that the dynamic greeting functioned flawlessly and that the contact form 
									functionalities were intact. Once confident in the stability, I deployed the updated 
									version of my portfolio to the cloud.
								</p>
						</div>

						<div>
						<br/>
								<h4>Conclusion</h4>
								<p>
									The implementation of a dynamic greeting on my portfolio not only adds a touch of
									personalization but also exemplifies the evolving nature of web development. It's a 
									testament to the power of combining creativity with technology, transforming a static
									website into a dynamic and engaging digital space.
								</p>
								<p>
									In conclusion, this project is a small but impactful step toward creating a more
									immersive and welcoming digital environment. As technology continues to advance,  
									the possibilities for personalization and user engagement are limitless.
									
								</p>
						</div>

						<div>
						<br/>
								<h4>Future Enhancements</h4>
								<p>
									Looking ahead, there's room for future enhancements. Considerations include
									expanding the dynamic greetings based on user preferences, incorporating user- 
									specific data for a more tailored experience, and exploring additional serverless 
									functionalities to further elevate the portfolio's interactivity.
								</p>
								<p>
									Bringing a dynamic greeting to my portfolio has not only added a personal touch but 
									has also opened doors to future explorations in the realm of user-centric web 
									development. As we embrace the dynamic nature of the digital world, the journey of 
									crafting unique and engaging experiences continues.	
								</p>
						</div>




					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {

	return {
		date: "6 Feb 2024",
		title: "Elevating Code Quality with Effective Code Review Practices in AWS Development",
		description:
			"Elevating Code Quality with Effective Code Review Practices in AWS Development",
		style: ``,
		keywords: [
			"Artificial Intelligence in Healthcare",
			"Sudip Adhikari",
			"Sudip Adhikari",
			"Sudip Adhikari",
		],
		body: (
			<React.Fragment>
				
				<div className="article-content">
					
					<div className="paragraph">
						<div>
							<br/>
							<h3>Introduction</h3>
								<p>
									In the fast-paced realm of software development, code review stands as a crucial practice for ensuring code quality, fostering collaboration, 
									and maintaining project integrity. As a seasoned developer with extensive experience in AWS (Amazon Web Services) environments, I've witnessed 
									firsthand the transformative impact of effective code review practices within AWS development projects. In this article, I'll share insights 
									into the art of code review, tailored specifically for AWS projects, drawing from my own experiences and best practices in the field.
								</p>
						</div>

						<div>
							<h4>Understanding the Purpose of Code Review in AWS Development:</h4>
							<p>
								In my tenure as a lead developer on AWS projects, I've come to understand the critical role that code review plays in ensuring that applications adhere 
								to AWS best practices and architectural principles. For instance, during a recent project migration to AWS, our team conducted thorough code reviews to 
								validate AWS resource configurations, ensuring compliance with AWS security standards and optimizing performance. By incorporating AWS-specific considerations 
								into the code review process, we were able to mitigate risks, enhance security, and maximize the benefits of cloud-native development.
							 </p>
						</div>

						<div>
							<h4>Striking the Balance Between Speed and Thoroughness in AWS Code Review:</h4>
							<p>
								In my role as a technical lead, I've encountered the challenge of striking the right balance between speed and thoroughness in AWS code reviews. While it's crucial to 
								conduct thorough reviews to validate AWS resource configurations and security measures, excessive nitpicking can impede productivity and delay project timelines. 
								To address this challenge, our team implemented automated tools for AWS resource validation and security scanning, allowing us to streamline the code review process 
								while maintaining code quality and adherence to AWS standards.
							 </p>
						</div>

						<div>
							<h4>Leveraging AWS Tools and Services for Enhanced Code Review:</h4>
							<p>
								Throughout my career, I've leveraged various AWS tools and services to augment the code review process and enforce AWS best practices. For instance, 
								during a recent project, we integrated AWS CodePipeline into our development workflow to automate build, test, and deployment pipelines. Additionally, 
								we utilized AWS Config and AWS Trusted Advisor to gain insights into AWS resource configurations, compliance checks, and cost optimization recommendations, 
								enabling us to proactively address issues during code review and ensure alignment with AWS best practices.
							 </p>
						</div>

						<div>
							<h4>Cultivating a Culture of Continuous Improvement in AWS Development:</h4>
							<p>
								In my experience, fostering a culture of continuous improvement is paramount in AWS development environments. By organizing knowledge sharing sessions on AWS best 
								practices, conducting regular architecture reviews, and providing opportunities for AWS-focused training and certification, we empowered developers to deepen their 
								AWS expertise and drive innovation in cloud-native solutions. This emphasis on continuous learning and improvement not only enhanced our team's capabilities but 
								also fostered a culture of collaboration, accountability, and excellence in AWS development.
							 </p>
						</div>

						

						<div>
							<h4>Conclusion:</h4>
							<p>
								In conclusion, effective code review practices are essential for ensuring code quality, security, and adherence to AWS best practices in cloud-native 
								development. Drawing from real-world experiences and best practices, developers can incorporate AWS-specific considerations into the code review process, 
								embrace constructive feedback, strike the right balance between speed and thoroughness, leverage AWS tools and services, and foster a culture of continuous 
								improvement. To delve deeper into effective code review practices in AWS environments, I encourage readers to explore AWS documentation, participate in AWS 
								training programs, and actively engage with AWS community forums and events. By embracing the art of code review in AWS development, developers can maximize 
								the benefits of cloud-native solutions and drive innovation in the cloud.

							 </p>
						</div>

						
					</div>
				</div>
			</React.Fragment>
		),
	};
}


const myArticles = [article_1, article_2, article_3];

export default myArticles;
