	const INFO = {
		main: {
			title: "Reactfolio by Sudip",
			name: "Sudip Adhikari",
			email: "sudip.adhikari247@gmail.com",
			logo: "../logo.png",
		},

		socials: {
			twitter: "https://twitter.com/SudipAdhikari01",
			github: "https://github.com/sudipadhikari01",
			linkedin: "https://linkedin.com/in/sudipadhikari247/",
			instagram: "https://instagram.com/",
			stackoverflow: "https://stackoverflow.com/",
			facebook: "https://facebook.com/",
		},

		homepage: {
			title: "Full-stack web and mobile app developer.",
			description:
				"Reliable Java Developer with professional experience administering projects through all phases of the software development life cycle. Streamline backend development, application integration, and technical support while improving performance and functionality. Skilled in implementing projects using technologies such as Java 8, React, Spring Boot, Hibernate, MySQL, Microservice, AWS, IntelliJ, REST API, and Jira.",
		},

		about: {
			title: "Senior Fullstack Java Developer ",
			description:
				"I’m a passionate Java developer with 5 years of experience. I have worked in business domains like Ecommerce, Lottery System, Broker System and Travel and Tour System. I’ve strong foundation of Java, Spring boot, JPA, hibernate, PostgreSQL, microservices and React.  I can work well with both Front end and backend. But during my career I was more aligned to backend. Like 80% backend and 20% frontend. I have also collaborated with DevOps team and QA for the better quality of product. Apart from that I’m also a quick problem solver, highly motivated and good communicator so, I will always collaborate well with my coworkers. So, I’m confident my skills and expertise will fit what you are looking for. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
		},

		articles: {
			title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
			description:
				"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		},

		projects: [
			{
				title: "Workbench",
				description:
					"As a Senior Java Fullstack Developer at 7-Eleven's Workbench project, I spearheaded the implementation of a microservices architecture using REST APIs. I utilized React for creating the responsive UI, integrated Kafka for streaming messages, and employed Spring Batch for efficient batch processing. My expertise extended to managing data with PostgreSQL and Cassandra databases, ensuring a robust and scalable system. These contributions significantly enhanced the overall functionality and performance of the 7-Eleven Workbench platform.",
				logo: "images/7-eleven-logo.jpeg",
				linkText: "View Project",
				link: "https://github.com",
			},

			{
				title: "Khusi Lottery",
				description:
					"In collaboration with Crupee, I played a key role in developing two distinct systems: a lottery system and a travel and tours platform. Leveraging Spring Boot, I crafted REST APIs, employing Hibernate for efficient Object-Relational Mapping (ORM) with PostgreSQL and MongoDB databases. The integration of ActiveMQ facilitated seamless messaging within the systems.For the user interface, I utilized React.js to create dynamic and responsive components, seamlessly integrating them with the backend services. This comprehensive approach resulted in the successful delivery of both projects, showcasing my proficiency in full-stack development and my ability to implement robust and scalable solutions for diverse applications.",
				logo: "images/khusi-lottery.jpeg",
				linkText: "View Project",
				link: "https://github.com",
			},

			{
				title: "Broker Management System",
				description:
					"Led the development of a Broker Management System at Mandala System Pvt Ltd. Migrated from Servlets to Spring Boot, integrating Hibernate with MySQL. Developed RESTful APIs and revamped legacy projects for enhanced performance. Crafted a refined user interface with HTML, CSS, JavaScript, and jQuery. Conducted rigorous code reviews, ensuring adherence to best practices and optimal code quality.",
				logo: "images/broker-management-image.jpeg",
				linkText: "View Project",
				link: "https://github.com",
			}

			
		],
	};

	export default INFO;
